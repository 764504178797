/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 121번째 로그에서는 깃헙 장애, StackOverflow 매각, freenode 직원 이탈에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "깃헙 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.githubstatus.com/incidents/76nv9h8pmkv4"
  }, "GitHub Status - Incident with GitHub Actions, API Requests, Git Operations, Issues, GitHub Packages, GitHub Pages, Pull Requests, and Webhooks")), "\n"), "\n", React.createElement(_components.h3, null, "PolarDB for PostgreSQL"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/alibaba/PolarDB-for-PostgreSQL"
  }, "PolarDB for PostgreSQL")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ant.design/"
  }, "Ant Design - The world's second most popular React UI framework")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://deno.land/"
  }, "Deno - A secure runtime for JavaScript and TypeScript - DenoLand")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ui.toast.com/"
  }, "TOAST UI :: Make Your Web Delicious!")), "\n"), "\n", React.createElement(_components.h3, null, "오픈소스 Email Alias - SimpleLogin"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://simplelogin.io/"
  }, "SimpleLogin | Open-source email alias solution")), "\n"), "\n", React.createElement(_components.h3, null, "StackOverflow 매각"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wsj.com/articles/software-developer-community-stack-overflow-sold-to-tech-giant-prosus-for-1-8-billion-11622648400"
  }, "Stack Overflow Sold to Tech Giant Prosus for $1.8 Billion  - WSJ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.prosus.com/"
  }, "Prosus")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.joelonsoftware.com/"
  }, "Joel on Software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.codinghorror.com/"
  }, "Coding Horror")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Jeff_Atwood"
  }, "Jeff Atwood - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Joel_Spolsky"
  }, "Joel Spolsky - Wikipedia")), "\n"), "\n", React.createElement(_components.h3, null, "네이버 소프트웨어 서비스 종료"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://software.naver.com/notice/notice.nhn?board=/read/1000003503/10000000000030671499#"
  }, "네이버 소프트웨어 서비스 종료 안내")), "\n"), "\n", React.createElement(_components.h3, null, "freenode 직원 이탈"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://arstechnica.com/gadgets/2021/05/freenode-irc-has-been-taken-over-by-the-crown-prince-of-korea/"
  }, "Freenode IRC staff resign en masse after takeover by Korea’s “crown prince” | Ars Technica")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://libera.chat/"
  }, "Libera Chat | A next-generation IRC network for FOSS projects collaboration!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://discord.com/"
  }, "Discord | Your Place to Talk and Hang Out")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://slack.com/"
  }, "Welcome to your new HQ | Slack")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.redmine.org/"
  }, "Redmine")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://subversion.apache.org/"
  }, "Apache Subversion")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://trac.edgewall.org/"
  }, "The Trac Project")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Google_Wave"
  }, "Google Wave - Wikipedia")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
